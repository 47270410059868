<template>
    <v-container id="task-tables" fluid tag="section">
        <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('task.title')"
        class="px-5 py-3"
        >
            <dialog-message
                :dialog_message="dialog_message"
                :message="message"
            ></dialog-message>
            <v-dialog
                v-model="dialog_form"
                max-width="500px"
                @click:outside="close"
            >
                <v-card>
                    <template v-if="statusLoading">
                        <v-col cols="12" class="text-center">
                            <v-progress-circular
                            :size="70"
                            :width="7"
                            color="primary"
                            indeterminate
                            ></v-progress-circular>
                        </v-col>
                        <v-col cols="12" class="text-center text-h3">
                            {{ $t('common.waiting') }}
                        </v-col>
                    </template>
                    <template v-else>
                        <v-card-title>
                            <div class="text-h4">
                                {{ item.state|getText(TaskStatusItems)|Translate }}
                            </div>
                        </v-card-title>
                    </template>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            {{ $t("common.cancel") }}
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="getResult">
                            {{ $t("task.getResult") }}
                        </v-btn>
                        <template v-if="!statusLoading&&item.state==TaskStatus.Ongoing">
                            <v-btn color="red darken-1" text @click="stopTask">
                                {{ $t("task.stopTask") }}
                            </v-btn>
                        </template>
                        <template v-if="!statusLoading&&task_timeout">
                            <v-btn color="blue darken-1" text @click="askTranscript">
                                {{ $t("task.askTranscript") }}
                            </v-btn>
                        </template>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalItems"
                :footer-props="footerProps"
                multi-sort
            >
                <template v-slot:item.lbtype="{ item }">
                    {{ item.lbtype|getText(LBTypeItems)|Translate }}
                </template>
                <template v-slot:item.status="{ item }">
                    {{ item.status|getText(TaskStatusItems)|Translate }}
                </template>
                <template v-slot:item.create_time="{ item }">
                    {{ TransDate(item.create_time) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-flex>
                        <v-btn
                        color="primary"
                        class="mx-2"
                        small
                        @click="checkStatus(item)"
                        >{{ $t("task.checkStatus") }}</v-btn
                        >
                        <v-btn color="red" class="mx-2" small
                        @click="RemoveOwner(item)"
                        >{{
                        $t("common.delete")
                        }}</v-btn>
                    </v-flex>
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                        dense
                        :show-first-last-page="footerProps.showFirstLastPage"
                        :show-current-page="footerProps.showCurrentPage"
                        :first-icon="footerProps.firstIcon"
                        :last-icon="footerProps.lastIcon"
                        :prev-icon="footerProps.prevIcon"
                        :next-icon="footerProps.nextIcon"
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        :itemsPerPageOptions="footerProps.itemsPerPageOptions"
                    />
                </template>
                <template slot="no-data">
                <div>
                    {{ $t('common.noData') }}
                </div>
                </template>
            </v-data-table>
        </base-material-card>
    </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { LBType, LBTypeItems, TaskStatus, TaskStatusItems } from '@/definition'

export default {
    data: (vm) => ({
        LBType: LBType,
        LBTypeItems: LBTypeItems,
        TaskStatus: TaskStatus,
        TaskStatusItems: TaskStatusItems,
        dialog_form: false,
        statusLoading: true,
        loading: false,
        items: [],
        options: {},
        totalItems: 0,
        footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: "mdi-chevron-double-left",
        lastIcon: "mdi-chevron-double-right",
        prevIcon: "mdi-chevron-left",
        nextIcon: "mdi-chevron-right",
        itemsPerPageOptions: [5, 10, 20],
        },
        dialog_message: false,
        message: {
            title: "",
            text: ""
        },
        headers: [
            {
                sortable: false,
                text: vm.$i18n.t("task.lbtype"),
                value: "lbtype",
            },
            {
                sortable: false,
                text: vm.$i18n.t("task.status.title"),
                value: "status",
            },
            {
                sortable: false,
                text: vm.$i18n.t("urbanRenewal.name"),
                value: "urban_renewal.name",
            },
            {
                sortable: false,
                text: vm.$i18n.t("common.creater"),
                value: "user.username",
            },
            {
                sortable: false,
                text: vm.$i18n.t("task.create_time"),
                value: "create_time",
            },
            {
                sortable: false,
                // text: vm.$i18n.t("common.actions"),
                text: '',
                value: "actions",
            },
        ],
        item: {
            status: null,
            task_id: null,
            state: 0,
            time: null
        },
        task_id: null,
        task_timeout: false,
    }),
    computed: {
        ...mapState(["token"]),
    },
    methods: {
        ...mapMutations(["setTitle"]),
        close() {
            this.dialog_form = false;
            this.loading = false;
        },
        getApi() {
            this.loading = true;
            const currentObj = this;
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/transcript/";
            let config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + this.token,
                },
                params: {
                    page: page,
                    page_size: itemsPerPage,
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                currentObj.loading = false;
                currentObj.setResult(response.data);
                })
                .catch(function (error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("get-api-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        setResult(data) {
            this.totalItems = data.count;
            this.items = data.results;
        },
        checkStatus(task){
            this.statusLoading = true;
            this.dialog_form = true;
            this.loading = true;
            this.task_id = task.id;
            if(task.status==this.TaskStatus.Timeout){
                this.task_timeout = true
            } else {
                this.task_timeout = false
            }
            const currentObj = this;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/transcript/" + task.id + "/checkStatus/";
            let config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + this.token,
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                    currentObj.loading = false;
                    currentObj.setStatusResult(response.data);
                })
                .catch(function (error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("get-api-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        setStatusResult(data){
            this.item = data;
            this.statusLoading = false;
        },
        getResult(){
            this.statusLoading = true;
            this.loading = true;
            const currentObj = this;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/transcript/" + this.task_id + "/getResult/";
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + this.token,
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                    currentObj.close();
                    let msg
                    if (response.data['status']=='OK'){
                        msg = currentObj.$i18n.t('task.resultSuccess')
                    } else {
                        msh = currentObj.$i18n.t('task.resultFailed')
                    }
                    currentObj.message.title = msg;
                    currentObj.message.text = "";
                    currentObj.dialog_message = !currentObj.dialog_message;
                })
                .catch(function (error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("get-api-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        stopTask(){
            this.statusLoading = true;
            this.loading = true;
            const currentObj = this;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/transcript/" + this.task_id + "/stopTask/";
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + this.token,
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                    currentObj.close();
                    let msg
                    if (response.data['status']=='OK'){
                        msg = currentObj.$i18n.t('task.stopSuccess')
                    } else {
                        msg = currentObj.$i18n.t('task.stopFailed')
                    }
                    currentObj.message.title = msg;
                    currentObj.message.text = "";
                    currentObj.dialog_message = !currentObj.dialog_message;
                })
                .catch(function (error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("get-api-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        askTranscript(){
            this.statusLoading = true;
            this.loading = true;
            const currentObj = this;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/transcript/" + this.task_id + "/askTranscript/";
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + this.token,
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                    currentObj.close();
                    let msg
                    currentObj.message.text = "";
                    if (response.data['status']=='OK'){
                        msg = currentObj.$i18n.t('task.askTranscriptSuccess')
                        if (response.data['no_data'].length > 0){
                            currentObj.message.text = '剩餘' + response.data['no_data'].length.toString() + '筆未完成';
                        }
                    } else {
                        msg = currentObj.$i18n.t('task.askTranscriptFailed')
                    }
                    currentObj.message.title = msg;
                    currentObj.dialog_message = !currentObj.dialog_message;
                })
                .catch(function (error) {
                    console.log(error);
                    console.log(error.response);
                    currentObj.loading = false;
                    currentObj.message.title = currentObj.$t("get-api-fail");
                    currentObj.message.text = JSON.stringify(error.response.data);
                    currentObj.dialog_message = !currentObj.dialog_message;
                })
        }
    },
    watch: {
        options: {
            handler() {
                this.getApi();
            },
        },
    },
    mounted() {
        this.setTitle(this.$i18n.t("title.Task"));
    }

}
</script>
